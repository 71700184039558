.complete-center {
    height: 100vh;
}

.sums-color {
    color: #1ab394;
}

a:hover {
    color: inherit !important;
    text-decoration: none !important;
}

.logo {
    width: 100px;
    height: 50px;
    object-fit: contain;
}

.overlay {
    display: flex;
    position: fixed; /* Sit on top of the page content */
    display: block; /* Hidden by default */
    width: 100vw; /* Full width (cover the whole page) */
    height: 100vh; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.95); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
}

.toggle-large {
    display: none;
}

@media only screen and (min-width: 768px) {
    .toggle-large {
        display: block;
    }
}